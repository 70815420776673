import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    borderRadius: theme.spacing(1),
    padding     : theme.spacing(3), 
  },
  detailsContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  detailIcon: {
    fontSize: theme.spacing(6),
    color   : colors.SESAMSEC_SYS_LIGHT_OUTLINE_SILENT
  },
  skeleton: {
    marginBottom: theme.spacing(2)
  },
  skeletonChips: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2)
  },
  detailTitle: {
    marginBottom: theme.spacing(2),
    fontSize    : theme.spacing(2),
    fontWeight  : '700'
  },
}));