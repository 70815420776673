import { Box, Button, Checkbox, Dialog, FormControlLabel, IconButton, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ArrowOutward } from '@mui/icons-material';
import React, { useState } from 'react';
import useStyles from './styles';

const ModalEntity = (props) => {
  const { entity, onEntityClick } = props;
  const classes = useStyles();

  const handleClick = (e) => {
    e.preventDefault();
    onEntityClick(entity);
  }

  return (
    <Box className={classes.entityContainer}>
      <Typography onClick={handleClick}>{ entity.name }</Typography>
      <IconButton onClick={handleClick} className={classes.entityIcon}>
        <ArrowOutward fontSize='medium'/>
      </IconButton>
    </Box>
  )
}

/**
 * @param {object} props - The `ActionModal` component accepts the following props:
 * @property {string} title - The title displayed at the top of the modal.
 * @property {boolean} isOpen - Controls the visibility of the modal.
 * @property {function} onClose - Callback triggered when the modal is closed.
 * @property {string} description - Explains the purpose of the modal.
 * @property {Array} entities - A list of entities to display, each with an id and name.
 * @property {function} onEntityClick - Callback triggered when an entity in the list is clicked, typically used for navigation.
 * @property {function} onSubmit - Callback triggered on the "Submit" button click, receiving a boolean `isDetached` indicating if the detach option was selected.
 * @property {string} footerDescription - (Optional) Describes the option presented by the checkbox (detaching entities for future use).
 * @property {string} cancelButtonLabel - Custom text for the cancel button (default: "Cancel").
 * @property {string} submitButtonLabel - Custom text for the submit button (default: "Submit").
 * @returns The `ActionModal` component renders a dialog containing a title, description, a list of entities, an optional detach checkbox, and action buttons.
 */
const ActionModal = (props) => {
  const { title, isOpen, onClose, description, entities, onEntityClick, onSubmit,  
    footerDescription, cancelButtonLabel, submitButtonLabel } = props;
  
  const [isDetached, setIsDetached] = useState(false);

  const classes = useStyles();

  const handleDetach = () => {
    setIsDetached(isChecked => !isChecked);
  }

  const handleSubmit = () => {
    onSubmit(isDetached);
  }

  return (
    <Dialog
      id="deleteModal"
      modal="false"
      open={isOpen}
      onClose={() => {}}
      aria-labelledby="row-delete-dialog"
      aria-describedby="row-delete-dialog-table"
      className={classes.dialog}
    >
      <Paper id="deleteModalBodyContainer" className={classes.paper}>
        <Box>
          <Box className={classes.dialogTitleContainer}>
            <Typography className={classes.dialogTitle}>{ title }</Typography>
            <IconButton onClick={onClose}>
              <Close fontSize='medium'/>
            </IconButton>
          </Box>
          <Box className={classes.mainContent}>
            <Typography>
              { description }
            </Typography>
            <Box className={classes.entitiesContainer}>
              {
                entities.map((item, index) => (
                  <ModalEntity key={index} entity={item} onEntityClick={onEntityClick} />
                ))
              }
            </Box>
            {
              footerDescription ? 
                <FormControlLabel
                  className={classes.checkbox}
                  control={
                    <Checkbox 
                      checked={isDetached} 
                      onClick={handleDetach} 
                      name="check" 
                      color='primary'
                    />
                  }
                  label={ footerDescription }
                />
              : 
                <></>
            }
          </Box>
        </Box>
        <Box id="deleteModalFooterContainer" className={classes.dialogActions}>
          <Button
            data-testid="cancel-button"
            id="cancelButton"
            size='medium'
            className={classes.dialogButton}
            onClick={onClose}
            >
            { cancelButtonLabel }
          </Button>
          <Button 
            data-testid="detach-button"
            id="detachButton" 
            size='medium' 
            variant="contained" 
            className={classes.dialogButton} 
            onClick={handleSubmit}
          >
            { submitButtonLabel }
          </Button>
        </Box>
      </Paper>
    </Dialog>
  );
}

export default ActionModal