import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    height    : theme.spacing(5),
    width     : theme.spacing(25),
  },
  subTitle: {
    height: theme.spacing(1.875),
    width : theme.spacing(18.75)
  }
}));