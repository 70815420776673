import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { MeetingRoom } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DETAILS_SKELETON, ENTITY_CHIPS_SKELETON } from '../../../utility/constants';
import useStyles from './styles';

const LoadingState = () => {
  const classes = useStyles()
  const { t }   = useTranslation();
  
  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid container>
        <Grid item md={7} lg={4} xl={3} sm={7} xs={12} className={classes.detailsContainer}>
          <MeetingRoom className={classes.detailIcon}/>
          <Box >
            <Typography className={classes.detailTitle}>{t('accesspoint-page.detailTitle')}</Typography>
            <Skeleton variant='rectangle' height={DETAILS_SKELETON} className={classes.skeleton}/>
            <Skeleton variant='rectangle' height={DETAILS_SKELETON} className={classes.skeleton}/>
            <Skeleton variant='rectangle' height={DETAILS_SKELETON} width={200}/>
          </Box>
        </Grid>
        <Grid item md={5} lg={8} xl={9} sm={5} xs={12}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={5} xl={4} sm={12} xs={12}>
            <Typography className={classes.detailTitle}>{t('accesspoint-page.readersLabel')}</Typography>
              <Skeleton variant='rectangle' height={ENTITY_CHIPS_SKELETON} width={400} className={classes.skeletonChips}/>
            </Grid>
            <Grid item md={12} lg={7} xl={8} sm={12} xs={12}>
              <Typography className={classes.detailTitle}>{t('accesspoint-page.profilesLabel')}</Typography>
              <Skeleton variant='rectangle' height={ENTITY_CHIPS_SKELETON} width={400} className={classes.skeletonChips}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}


export default LoadingState;