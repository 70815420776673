import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../theme/colors';

export default makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: colors.TOOLTIP_DARK_GRAY,
    borderRadius   : theme.spacing(0.25),
    color          : colors.WHITE,
    fontSize       : theme.spacing(1.75),
    textAlign      : 'center'
  },
  iconTooltip: {
    color          : colors.DARK_GRAY,
    display        : 'flex',
    gap            : theme.spacing(1),
    justifyContent : 'center',
    marginRight    : theme.spacing(-1)
  },
  icon: {
    height: `${theme.spacing(3)}px !important`,
    width : `${theme.spacing(3)}px !important`,
    color : colors.DARK_GRAY_ICON
  },
}));