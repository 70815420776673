import { LOCAL } from "../utility/constants";

const AUTH_BASE_PATH         = window.ENVIRONMENT === LOCAL ? window.AUTH_BASE_PATH : window.API_BASE_PATH;
const PAC_BASE_PATH          = window.ENVIRONMENT === LOCAL ? window.PAC_BASE_PATH : window.API_BASE_PATH;
const KEYCLOAK_PATH          = window.KEYCLOAK_URL;
const DEVICE_MANAGEMENT_PATH = window.DEVICE_MANAGEMENT_BASE_PATH;
const CONFIGURATION_PATH     = window.CONFIGURATION_URL;

const api = {
  FORGOT_PASSWORD                                   : `${AUTH_BASE_PATH}/public/forgot-password`,
  LOGIN                                             : `${AUTH_BASE_PATH}/public/login`,
  NEW_PASSWORD                                      : `${AUTH_BASE_PATH}/public/new-password`,
  AUTHORIZATIONS                                    : `${AUTH_BASE_PATH}/authorizations`,
  AUTHORIZATIONS_FIND_BY_ADMINISTRATOR_ID           : `${AUTH_BASE_PATH}/authorizations/search/findByUserId`,
  ADMINISTRATORS_BY_TOKEN                           : `${AUTH_BASE_PATH}/auth/search/findByToken`,
  KEYCLOAK_PERMISSIONS                              : `${KEYCLOAK_PATH}/realms/sesamsec/protocol/openid-connect/token`,
  ADMINISTRATORS                                    : `${AUTH_BASE_PATH}/users`,
  ADMINISTRATORS_BY_EMAIL                           : `${AUTH_BASE_PATH}/users/search/findByEmail`,
  LOCATION_LEVELS                                   : `${PAC_BASE_PATH}/locationLevels`,
  LOCATIONS                                         : `${PAC_BASE_PATH}/locations`,
  LOCATIONS_COUNT                                   : `${PAC_BASE_PATH}/locations/count`,
  LOCATIONS_FIND_BY_ADMINISTRATOR_ID                : `${PAC_BASE_PATH}/locations/search/find-by-userId`,
  CREDENTIALS                                       : `${PAC_BASE_PATH}/credentials`,
  CREDENTIALS_USER_ASSIGN                           : `${PAC_BASE_PATH}/credentials/assign-user`,
  CREDENTIALS_SEARCH                                : `${PAC_BASE_PATH}/credentials/search/find-credential-number-containing`,
  CREDENTIALS_ACTIVE                                : `${PAC_BASE_PATH}/credentials/search/findByUserIdNull`,
  CREDENTIALS_BY_ID                                 : `${PAC_BASE_PATH}/credentials/search/findByCredentialId`,
  CREDENTIALS_BY_USER_IDS                           : `${PAC_BASE_PATH}/credentials/search/find-all-credentials-by-user-ids`,
  CREDENTIALS_INVALID                               : `${PAC_BASE_PATH}/credentials/search/findByValidUntilBeforeOrValidFromAfter`,
  CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER_IN          : `${PAC_BASE_PATH}/credentials/search/findByCredentialNumberIn`,
  CREDENTIALS_FIND_BY_CREDENTIAL_NUMBER             : `${PAC_BASE_PATH}/credentials/search/findByCredentialNumber`,
  CREDENTIALS_SEARCH_ORDER_BY_LOCATION_NAME_ASC     : `${PAC_BASE_PATH}/credentials/search/findByCredentialNumberContainingOrderByLocationNameAsc`,
  CREDENTIALS_SEARCH_ORDER_BY_LOCATION_NAME_DESC    : `${PAC_BASE_PATH}/credentials/search/findByCredentialNumberContainingOrderByLocationNameDesc`, 
  CREDENTIALS_SEARCH_AVAILABLE                      : `${PAC_BASE_PATH}/credentials/search/unassigned`,
  CREDENTIALS_SEARCH_ORDER_BY_USER_ASC              : `${PAC_BASE_PATH}/credentials/search/find-credential-number-sort-user-asc`,
  CREDENTIALS_SEARCH_ORDER_BY_USER_DESC             : `${PAC_BASE_PATH}/credentials/search/find-credential-number-sort-user-desc`,
  CONTROLLERS                                       : `${DEVICE_MANAGEMENT_PATH}/controllers`,
  CONTROLLER_CONFIGURATIONS                         : `${CONFIGURATION_PATH}/controller`,
  HOLIDAYS                                          : `${PAC_BASE_PATH}/holidays`,
  HOLIDAY_ITEMS                                     : `${PAC_BASE_PATH}/holidayItems`,
  HOLIDAY_ITEMS_FIND_BY_HOLIDAY_ID_NOT              : `${PAC_BASE_PATH}/holidayItems/search/findByHolidayItemIdNotInAndHolidayId`,
  HOLIDAY_ITEMS_IMPORT_UPDATE_CHECK                 : `${PAC_BASE_PATH}/holidayItems/search/findByNameInAndDateInAndHolidayIdAndHolidayItemIdNotIn`,
  HOLIDAYS_BY_ID                                    : `${PAC_BASE_PATH}/holidays/search/findByHolidayId`,
  HOLIDAYS_BY_NAME                                  : `${PAC_BASE_PATH}/holidays/search/findByNameContaining`,
  HOLIDAYS_SEARCH_ORDER_BY_LOCATION_NAME_ASC        : `${PAC_BASE_PATH}/holidays/search/findByNameContainingOrderByLocationNameAsc`,
  HOLIDAYS_SEARCH_ORDER_BY_LOCATION_NAME_DESC       : `${PAC_BASE_PATH}/holidays/search/findByNameContainingOrderByLocationNameDesc`,
  ROLES                                             : `${PAC_BASE_PATH}/roles`,
  ROLE_USERS                                        : `${PAC_BASE_PATH}/roles/:id/users`,
  ROLES_BY_ID                                       : `${PAC_BASE_PATH}/roles/search/findByRoleId`,
  ROLES_BY_NAME                                     : `${PAC_BASE_PATH}/roles/search/findByNameContaining`,
  ROLES_SEARCH_ORDER_BY_LOCATION_NAME_ASC           : `${PAC_BASE_PATH}/roles/search/findByNameContainingOrderByLocationNameAsc`,
  ROLES_SEARCH_ORDER_BY_LOCATION_NAME_DESC          : `${PAC_BASE_PATH}/roles/search/findByNameContainingOrderByLocationNameDesc`,
  PROFILES                                          : `${PAC_BASE_PATH}/profiles`,
  PROFILES_BY_NAME                                  : `${PAC_BASE_PATH}/profiles/search/findByNameContaining`,
  PROFILES_SEARCH_ORDER_BY_LOCATION_NAME_ASC        : `${PAC_BASE_PATH}/profiles/search/findByNameContainingOrderByLocationNameAsc`,
  PROFILES_SEARCH_ORDER_BY_LOCATION_NAME_DESC       : `${PAC_BASE_PATH}/profiles/search/findByNameContainingOrderByLocationNameDesc`,
  ACCESS_POINTS                                     : `${DEVICE_MANAGEMENT_PATH}/accessPoints`,
  ACCESS_POINTS_BY_PROFILE_ID                       : `${DEVICE_MANAGEMENT_PATH}/accessPoints/search/findAccessPoints`,
  ACCESS_POINTS_SEARCH                              : `${PAC_BASE_PATH}/accessPoints/search/findByNameContaining`,
  SCHEDULES                                         : `${PAC_BASE_PATH}/schedules`,
  SCHEDULES_ITEMS                                   : `${PAC_BASE_PATH}/scheduleItems`,
  SCHEDULES_BY_ID                                   : `${PAC_BASE_PATH}/schedules/search/findByScheduleId`,
  SCHEDULES_BY_NAME                                 : `${PAC_BASE_PATH}/schedules/search/findByNameContaining`,
  SCHEDULES_SEARCH_ORDER_BY_LOCATION_NAME_ASC       : `${PAC_BASE_PATH}/schedules/search/findByNameContainingOrderByLocationNameAsc`,
  SCHEDULES_SEARCH_ORDER_BY_LOCATION_NAME_DESC      : `${PAC_BASE_PATH}/schedules/search/findByNameContainingOrderByLocationNameDesc`,
  EVENTS                                            : `${DEVICE_MANAGEMENT_PATH}/events`,
  EVENT_CONFIGURATIONS                              : `${CONFIGURATION_PATH}/event`,
  EVENT_FILTER_EVENT_YEARLY_REPORT                  : `${DEVICE_MANAGEMENT_PATH}/eventYearlyReports`,
  EVENT_FILTER_EVENT_MONTHLY_REPORT                 : `${DEVICE_MANAGEMENT_PATH}/eventMonthlyReports`,
  EVENT_GET_CONTROLLERS                             : `${DEVICE_MANAGEMENT_PATH}/events/search/eventControllers`,
  VALIDITY_DATE_CONFIGURATIONS                      : `${CONFIGURATION_PATH}/validity-date`,
  GROUPS                                            : `${PAC_BASE_PATH}/groups`,
  GROUPS_SEARCH                                     : `${PAC_BASE_PATH}/groups/search/findByNameContaining`,
  OPEN_SYNC_COMMANDS                                : `${DEVICE_MANAGEMENT_PATH}/unsyncedControllers/countUnsyncedData`,
  USER_UPLOAD_CSV                                   : `${PAC_BASE_PATH}/import/usersJson`,
  USER_CSV_CHECK                                    : `${PAC_BASE_PATH}/import/checkUsersJson`,
  USER_EXPORT                                       : `${PAC_BASE_PATH}/export/users`,
  USERS                                             : `${PAC_BASE_PATH}/users`,
  USER_WITH_CREDENTIALS                             : `${PAC_BASE_PATH}/users/search/find-by-credentials`,
  DEVICE_REQUEST                                    : `${DEVICE_MANAGEMENT_PATH}/device_request`,
  USER_ROLES_USER_ASSIGN                            : `${PAC_BASE_PATH}/user-roles/assign-user`,
  SYNC_USER_ROLES                                   : `${PAC_BASE_PATH}/user-roles/sync-user-role`,
  USER_ROLES_BY_ID                                  : `${PAC_BASE_PATH}/user-roles/search/findByRoleId`,
  USER_SYNC_CONFIGURATIONS                          : `${CONFIGURATION_PATH}/user-sync`,
  AREAS                                             : `${PAC_BASE_PATH}/areas`,
  AREA_AUDIT_LOGS                                   : `${PAC_BASE_PATH}/audit-logs/areas`,
  LOCATION_AUDIT_LOGS                               : `${PAC_BASE_PATH}/audit-logs/locations`,
  SERVER_INFO                                       : `${CONFIGURATION_PATH}/server-info`,
};

export default api;