import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

const drawerWidth            = 310;
const drawerWidthCompactView = 600;

export default makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    // NOTE:
    //  don't remove,
    //  this is used to override the css of expansion panel.
    '& .MuiPaper-elevation1':{
      boxShadow: 'none'
    },
    '& .MuiPaper-elevation4':{
      boxShadow: 'none'
    },
    '& .MuiAccordion-root.Mui-expanded, & .MuiAccordionSummary-content.Mui-expanded':{
      margin       : 'auto',
      paddingBottom: 0,
      transition: 'none'
    },
    '& .MuiAccordionSummary-root.Mui-expanded,':{
      paddingBottom: 0,
      paddingTop   : 0,
      position     : 'initial',
      minHeight    : 0,
    },
    '& .MuiAccordion-root': {
      position: 'static',
    },
    '& .MuiAccordion-root:before':{
      transition: 'none',
    },
    '& MuiButtonBase-root.MuiAccordionSummary-root':{
      transition: 'none',
    }
  },
  appBar: {
    background  : theme.palette.common.white,
    transition  : theme.transitions.create(['width', 'margin'], {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.leavingScreen,
    }),
    width       : `calc(100% - 50px)`,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  appBarShift: {
    marginLeft  : drawerWidth,
    transition  : theme.transitions.create(['margin', 'width'], {
      easing    : theme.transitions.easing.easeOut,
      duration  : theme.transitions.duration.enteringScreen,
    }),
    width       : `calc(100% - ${drawerWidth}px)`
  },
  appBarCompactView: {
    marginLeft  : drawerWidth,
    transition  : theme.transitions.create(['margin', 'width'], {
      easing    : theme.transitions.easing.easeOut,
      duration  : theme.transitions.duration.enteringScreen,
    }),
    width       : '100%',
    zIndex      : '20'
  },
  avatarButton: {
    justifyContent  : 'flex-end'
  },
  avatar: {
    backgroundColor : theme.palette.secondary.main
  },
  drawerPaper: {
    display         : 'flex',
    justifyContent  : 'space-between',
    position        : 'relative',
    whiteSpace      : 'nowrap',
    width           : drawerWidth,
    transition      : theme.transitions.create('width', {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.enteringScreen,
    }),
    overflowY       : 'auto',
    overflowX       : 'hidden',
  },
  drawerPaperHide: {
    display         : 'flex',
    justifyContent  : 'space-between',
    position        : 'relative',
    whiteSpace      : 'nowrap',
    width           : '50px',
    transition      : theme.transitions.create('width', {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.enteringScreen,
    }),
    overflowY       : 'hidden',
    overflowX       : 'hidden',
  },
  drawerPaperCompactView: {
    display         : 'flex',
    justifyContent  : 'space-between',
    position        : 'absolute',
    whiteSpace      : 'nowrap',
    width           : drawerWidthCompactView,
    transition      : theme.transitions.create('width', {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.enteringScreen,
    }),
    overflowY       : 'auto',
    overflowX       : 'hidden',
    height          : `calc(100% - 67px)`,
    top	            : '67px',
    zIndex          : '19',
    '& .MuiList-root': {
      paddingTop: theme.spacing(1.875),
    },
  },
  drawerPaperHideCompactView: {
    display         : 'flex',
    justifyContent  : 'space-between',
    position        : 'absolute',
    whiteSpace      : 'nowrap',
    width           : '50px',
    transition      : theme.transitions.create('width', {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.enteringScreen,
    }),
    overflowY       : 'hidden',
    overflowX       : 'hidden',
    height          : `calc(100% - 67px)`,
    top             : '67px',
    zIndex          : '19',
    '& .MuiList-root': {
      paddingTop: theme.spacing(1.875),
    },
  },
  list: {
    '& .MuiListItemIcon-root': {
      alignItems : 'center',
      color      : theme.palette.primary.main,
      minWidth   : theme.spacing(5)
    },
    '& .Mui-selected': {
      backgroundColor : theme.palette.primary.main,
      color           : theme.palette.common.white,
      '& .MuiListItemIcon-root': {
        color : theme.palette.common.white,
      }
    },
    '& .Mui-selected:hover, & .MuiListItem-button:hover': {
      backgroundColor : theme.palette.primary.dark,
      color           : theme.palette.common.white,
      '& .MuiListItemIcon-root': {
        color : theme.palette.common.white,
      }
    },
  },
  logo: {
    paddingLeft   :theme.spacing(0.5),
    paddingRight  :theme.spacing(2),
    width   : drawerWidth/2,
  },
  languagePosition:{
    alignSelf : 'center',
    paddingBottom : theme.spacing(3),
  },
  content: {
    position  : "absolute",
    right     : 0,
    top       : theme.spacing(8.75),
    bottom    : 0,
    overflow  : 'auto',
  },
  contentPushed: {
    left      : theme.spacing(38.75),
    transition  : theme.transitions.create('left', {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.enteringScreen,
    }),
  },
  contentNormal: {
    left      : theme.spacing(6.25),
    transition  : theme.transitions.create('left', {
      easing    : theme.transitions.easing.sharp,
      duration  : theme.transitions.duration.enteringScreen,
    }),
  },
  contentCompactView: {
    position  : "absolute",
    right     : 0,
    left      : theme.spacing(6.25),
    top       : theme.spacing(8.75),
    bottom    : 0,
    overflow  : 'auto',
  },
  partition: {
    alignItems      : 'center',
    backgroundColor : '#F5F5F5',
    color           : colors.SECONDARY,
    display         : 'flex',
    fontWeight      : "bold",
    height          : theme.spacing(3)
  },
  partitionText: {
    marginLeft  : theme.spacing(1.5),
  },
  popOver: {
    display : 'none'
  },
  backdrop: {
    opacity : '0.5 !important',
    zIndex  : '1200 !important'
  },
  wizardModal: {
    transitionDelay: '0.5s'
  },
  wizardTitle: {
    fontSize      : theme.spacing(2),
    fontWeight    : 'bold',
    marginBottom  : '5px',
    marginInline  : 'auto',
  },
  wizardImage: {
    height         : '52px',
  },
  wizardImage2: {
    height         : '50px',
    paddingBottom  : theme.spacing(0.5)
  },
  wizardButton: {
    border          : '2px solid',
    boxShadow       : theme.shadows[2],
    color           : theme.palette.primary.main,
    fontWeight      : 'bold',
    height          : theme.spacing(6.5),
    justifyContent  : 'space-between',
    paddingBottom   : theme.spacing(0.5),
    paddingLeft     : theme.spacing(0.5),
    paddingRight    : theme.spacing(0),
    paddingTop      : theme.spacing(1.25),
    width           : '100%',
    '&:hover': {
      backgroundColor  : theme.palette.primary.dark,
      border           : '2px solid',
      borderColor      : theme.palette.primary.dark,
      color            : 'white'
    }
  },
  wizardContainer: {
    marginRight     : theme.spacing(1),
  },  
  toolbar: {
    alignItems      : 'center',
    justifyContent  : 'space-between',
    marginLeft      : theme.spacing(-1.5),
  },  
  toolbarEnd: {
    alignItems      : 'center',
    justifyContent  : 'flex-end',
    marginLeft      : theme.spacing(-1.5),
  },  
  toolbarRight: {
    alignItems      : 'center',
    display         : 'flex',
    justifyContent  : 'flex-end'
  },
  locationFilter: {
    display         : 'inline-flex',
    justifyContent  : 'center',
    maxWidth        : 'none',
    paddingRight    : theme.spacing(2),
    paddingBottom   : theme.spacing(1),
    paddingLeft     : theme.spacing(2)
  },
  hamburgerMenu: {
    cursor          : 'pointer', 
    position        : 'sticky', 
    top             : '0', 
    backgroundColor : colors.WHITE, 
    zIndex          : '1000'
  }
}));