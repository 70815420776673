import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  paper: {
    width       : theme.spacing(75),
    borderRadius: theme.spacing(1.25)
  },
  dialogTitleContainer: {
    alignItems    : 'center',
    display       : 'flex',
    justifyContent: 'space-between',
    padding       : theme.spacing(3),
    paddingRight  : theme.spacing(2),
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize  : theme.spacing(2.75)
  },
  entitiesContainer: {
    maxHeight   : theme.spacing(20),
    marginBottom: theme.spacing(3),
    marginTop   : theme.spacing(3),
    overflowY   : 'auto'
  },
  entityContainer: {
    alignItems    : 'center',
    background    : colors.LIGHT_GRAY_ICON,
    borderRadius  : theme.spacing(1),
    display       : 'flex',
    justifyContent: 'space-between',
    marginBottom  : theme.spacing(0.5),
    padding       : theme.spacing(1),
    paddingBottom : theme.spacing(0.5),
    paddingTop    : theme.spacing(0.5),
    width         : '100%',
    '& .MuiTypography-root': {
      color: colors.PRIMARY,
      cursor: 'pointer'
    }
  },
  entityIcon: {
    padding: 0,
    color  : colors.PRIMARY,
  },
  mainContent: {
    padding   : theme.spacing(3),
    paddingTop: theme.spacing(0)
  },
  dialogActions: {
    alignItems     : 'center',
    backgroundColor: colors.LIGHT_GRAY,
    display        : 'flex',
    gap            : theme.spacing(2),
    justifyContent : 'flex-end',
    padding        : theme.spacing(3),
    '& .MuiButton-root': {
      color: colors.PRIMARY
    },
    '& .MuiButton-outlined': {
      border: '1px solid ' + colors.ERROR,
      color:  colors.ERROR
    },
    '& .MuiButton-contained': {
      background: colors.ERROR,
      color: colors.WHITE
    }
  },
  dialogButton: {
    borderRadius: theme.spacing(1),
    display     : 'flex',
    maxWidth    : theme.spacing(31.125),
    minWidth    : theme.spacing(7),
    padding     : theme.spacing(1.25),
    paddingLeft : theme.spacing(2),
    paddingRight: theme.spacing(2),
    width       : 'fit-content',
  },
  checkbox: {
    marginLeft: 0,
    '& .MuiTypography-root': {
      fontSize: theme.spacing(2),
      fontWeight: 700
    }
  }
}));