import { Menu, MenuItem } from '@material-ui/core';
import { EditOutlined, FolderOpen } from '@material-ui/icons';
import { default as React, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from "./styles";

const UserMenu = (props) => {
  const { handleCreate, handleImport, handleCloseMenu, open, anchorEl } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleCloseMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl, handleCloseMenu]);

  return (
    <Menu
      id="menu"
      className={classes.menu}
      anchorEl={anchorEl}
      onClose={handleCloseMenu}
      open={open}
      MenuListProps={{
        'aria-labelledby': 'button',
        onMouseLeave: handleCloseMenu
      }}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem
        id="createUserManually"
        className={classes.menuItem}
        onClose={handleCloseMenu}
        onClick={handleCreate}>
        <EditOutlined className={classes.icon} />
        {t('user-menu-component.addUserManually')}
      </MenuItem>
      <MenuItem
        id="addUserManually"
        className={classes.menuItem}
        onClose={handleCloseMenu}
        onClick={handleImport}>
        <FolderOpen className={classes.icon} />
        {t('user-menu-component.addUserViaCSV')}
      </MenuItem>
    </Menu>
  )
}

export default UserMenu;