import { Box, Chip as MuiChip, Typography } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';

const DetailsChip = (props) => {
  const classes = useStyles();
  const { icon, title, description, onDelete, endComponent, id } = props;

  const handleDelete = () => {
    onDelete();
  }

  return (
    <MuiChip
      id={id}
      icon={icon}
      size="small"
      color={"primary"}
      className={classes.chip}
      label={
        <Box className={classes.labelContainer}>
          <Box>
            <Typography className={classes.title} noWrap display="block" variant="caption">    
              { title }
            </Typography>
            <Typography className={classes.description} display="block" variant="caption">
              {description}
            </Typography>
          </Box>
          <Box className={classes.endContent}>
            { endComponent }
          </Box>
        </Box>
      }
      deleteIcon={
        (onDelete) ?
            <CancelIcon/>
          :
            <></>
      }
      onDelete={handleDelete}
    />
  )
}

export default DetailsChip;