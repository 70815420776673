import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  container: {
    paddingBottom : theme.spacing(2),
    maxWidth     : '100%'
  },
  contentHeader: {
    marginBottom : theme.spacing(2),
    marginTop    : theme.spacing(3)
  },
  paper: {
    borderRadius: theme.spacing(1),
    padding     : theme.spacing(3), 
  },
  detailsContainer: {
    display: 'flex',
    gap: theme.spacing(2)
  },
  detailTitle: {
    marginBottom: theme.spacing(2),
    fontSize    : theme.spacing(2),
    fontWeight  : '700'
  },
  detailsField: {
    display      : 'flex',
    flexDirection: 'column'
  },
  detailText: {
    marginBottom: theme.spacing(1),
    fontSize    : theme.spacing(1.75)
  },
  detailIcon: {
    fontSize: theme.spacing(6),
    color   : colors.SESAMSEC_SYS_LIGHT_OUTLINE_SILENT
  },
  chipContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(2)
  }
}));