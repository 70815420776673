import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { EVENT } from '../../../utility/constants';
import useStyles from './styles';

const SkeletonCol = (props) => {
  const { x, y, columnKey } = props;
  const classes = useStyles();

  return (
    <>
      {
        columnKey === EVENT ?
          <TableCell key={'skeleton-' + columnKey + '-' + x + '-' + y} className={classes.actions}>
            <Skeleton variant='rect' className={classes.icon}/>
            <Skeleton variant='text' className={classes.text}/>
          </TableCell>
        :
          <TableCell key={'skeleton-' + columnKey + '-' + x + '-' + y}>
            <Skeleton variant='text' className={classes.text}/>
          </TableCell>
      }
    </>
  )
}

const SkeletonRow = (props) => {
  const { columns, rowsPerPage, withCheckbox } = props;

  const skeletonColumns = columns.filter(column => !column.hidden);
  const columnKeys      = skeletonColumns.map(column => column.id)

  return (
    <TableBody>
      {
        [...Array(rowsPerPage)].map((row, x) =>
          <TableRow key={'skeleton-tr-' + row + x} >
            {
              withCheckbox && <SkeletonCol
                x={x}
                y={'checkbox'}
                columnKey={'checkbox'}
              />
            }
            {
              columnKeys.map((columnKey, y, _) =>
                <SkeletonCol
                  x={x}
                  y={y}
                  columnKey={columnKey}
                />
              )
            }
          </TableRow >
        )
      }
    </TableBody>
  )
}

export default SkeletonRow;