import { Box, Tooltip } from "@material-ui/core";
import { Dialpad } from "@material-ui/icons";
import { Badge } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import images from "../../../utility/images";
import useStyles from "./styles";

const CredentialsChip = (props) => {
  const { credentials, pin } = props;
  const classes  = useStyles();
  const { t } = useTranslation();

  const getCredentialsIconMessage = (credentials, pin) => {
    const hasCredential = credentials.length > 0;
    const hasPin = pin;

    if (hasCredential && hasPin) {
      return t('table-component.pinAndCredentialTooltip');
    } else if (hasCredential && !hasPin) {
      return t('table-component.credentialTooltip');
    } else if (!hasCredential && hasPin) {
      return t('table-component.pinTooltip');
    } else {
      return t('table-component.noPinOrCredentialTooltip');
    }
  }

  const tootlTipTitle = getCredentialsIconMessage(credentials, pin);

  return (
    <Tooltip
      title={tootlTipTitle}
      classes={{
        tooltip: classes.customTooltip
      }}
      className={classes.iconTooltip}
      placement="top"
    >
      <Box>
        {
          credentials.length > 0 ?
            <Badge className={classes.icon} />
            :
            <img src={images.BADGE_OFF_ICON} alt='badge-off-icon' className={classes.icon} />
        }
        {
          pin > 0 ?
            <Dialpad className={classes.icon} />
            :
            <img src={images.DIALPAD_OFF_ICON} alt='dialpad-off-icon' className={classes.icon} />
        }
      </Box>
    </Tooltip>
  )
}

export default CredentialsChip;
