import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  menu: {
    '& [aria-hidden="true"]': {
      position: 'static !important'
    }
  },
  menuItem: {
    padding      : theme.spacing(1),
    paddingInline: theme.spacing(1.5),
    color: colors.SESAMSEC_SYS_LIGHT_ON_SURFACE
  },
  icon: {
    marginRight: theme.spacing(1.5),
    height: `${theme.spacing(3)} !important`,
    width : `${theme.spacing(3)} !important`,
    color: colors.SESAMSEC_SYS_LIGHT_ON_SURFACE_VARIANT
  },
}));