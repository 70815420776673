import { makeStyles } from '@material-ui/core/styles';
import colors from '../../theme/colors';

export default makeStyles((theme) => ({
  chip: {
    backgroundColor: colors.SESAMSEC_SYS_LIGHT_SURFACE_CONTAINER_LOWEST,
    borderRadius   : theme.spacing(1),
    color          : colors.SESAMSEC_SYS_LIGHT_ON_SURFACE,
    display        : 'flex',
    height         : theme.spacing(6),
    justifyContent : 'flex-start',
    maxWidth       : theme.spacing(50),
    padding        : theme.spacing(1),
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(0.80)
    },
    '&:hover, &:focus': {
      backgroundColor: colors.SESAMSEC_SYS_LIGHT_SURFACE_CONTAINER_LOWEST,
    },
    '& .MuiTypography-caption': {
      lineHeight: 1.2
    }
  },
  labelContainer: {
    display: 'flex'
  },
  title: {
    color   : colors.DARK_GRAY,
    fontSize: theme.spacing(1.75),
    fontWeight: 'bold'
  },
  description: {
    color       : colors.DARK_GRAY_TEXT,
    fontSize    : theme.spacing(1.5)
  },
  endContent: {
    justifyContent: 'flex-end',
    display       : 'flex'
  }
}))