import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import clsx from 'clsx';
import React, { useContext } from "react";
import { Trans } from 'react-i18next';
import { withRouter } from 'react-router';
import { EventsFilterContext } from '../../context/eventsFIlterContext';
import { CLEAR_FILTER, initialState } from '../../reducer/eventsFilterReducer';
import { ACTION_ADD, ACTION_CREATE, ALL, CONTROLLERS, DASHBOARD, EVENTS, PAGE_TYPE } from "../../utility/constants";
import { generateURLQueryParam } from '../../utility/helper';
import useStyles from './styles';

const LocationState = (props) => {
  const { name, routeTo, history, isLast, subtitle } = props;
  const classes = useStyles();

  const handleRoute = () => {
    history.push(routeTo);
  }

  return (
    <>
      {
        isLast ? 
          <Typography 
            variant="body2" 
            component={'span'} 
            color="textPrimary" 
            className={classes.cursorDefault}> 
            <span id="breadcrumbCurrent">{subtitle}</span> </Typography>
        :
        <>
          <Link 
            id="breadCrumbPrevious" 
            variant="body2" 
            color="primary" 
            className={classes.cursorPointer} 
            onClick={handleRoute}
          >{name}</Link>
          <NavigateNextIcon fontSize="small" />
        </>
      }
    </>
  )
}

const State = (prop) => {
  const classes = useStyles();
  const { index, isLast, name, routeTo, mainPage, subtitle, history, pathname } = prop;
  const isCreate = name === ACTION_CREATE;
  const crumb = name.charAt(0) + name.slice(1);
  const { dispatch } = useContext(EventsFilterContext);

  const handleEventsRoute = () => {
    dispatch({ type: CLEAR_FILTER });

    const queryParam = generateURLQueryParam(initialState);
    const location = {
      pathname : `/${EVENTS}`,
      search   : queryParam,
    }
    history.push(location);
  }

  const handleRoute = () => {
    if (pathname.includes(EVENTS) && pathname.includes(PAGE_TYPE.VIEW)) {
      const { state } = history.location;
      if (state?.from === `/${DASHBOARD}`) {
        handleEventsRoute();
        return
      }
      history.goBack();
    } else {
      if (pathname.includes(EVENTS)) {
        handleEventsRoute();
      } else {
        history.push(routeTo);
      }
    }
  }
  
  if (mainPage) {
    return null;
  } else if (isLast) {
      if (isCreate) {
        const createSubtitle = pathname.includes(CONTROLLERS) ? ACTION_ADD : crumb;
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent"> <Trans i18nKey={`title-component.${createSubtitle}`}/> </span> </Typography>
      } else if (index === 0) {
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent"> <Trans i18nKey={`title-component.${crumb}`}/> </span> </Typography>
      } else {
        return <Typography variant="body2" component={'span'} color="textPrimary" className={classes.cursorDefault}> <span id="breadcrumbCurrent">{subtitle}</span> </Typography>
      }
  } else {
    return <Link id="breadCrumbPrevious" variant="body2" color="primary" className={classes.cursorPointer} onClick={() => handleRoute()}> <Trans i18nKey={`title-component.${crumb}`}/> </Link>
  }
}

const Title = (props) => {
  const { title, subtitle, history, location: { pathname }, listType, levels } = props;
  const pathnames = listType ? [EVENTS, title] : pathname.split('/').filter(x => x);
  const subTitle = listType ? title : subtitle;
  const classes = useStyles();

  const removeItemFromPathname = (item) => {
    const index = pathnames.indexOf(item);

    if (index > -1 ) {
      pathnames.splice(index, 1);
      return item;
    } else {
      return null;
    }
  }

  removeItemFromPathname('view');
  removeItemFromPathname('update');
  removeItemFromPathname('area');

  return (
    <>
      <Typography variant="h4" component="h4" className={clsx('bold')}>
        {title}
      </Typography>
      <Typography className={classes.breadcrumbs} component={'span'} variant="body2">
        <Breadcrumbs separator={listType === ALL.toLowerCase() ? '' :   <NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {
            levels?.length ?
              <>
                <State
                  index={0}
                  mainPage={false}
                  isLast={false}
                  name={pathnames[0]}
                  routeTo={`/${pathnames[0]}`}
                  subtitle={subTitle}
                  history={history}
                  pathname={pathname}
                  />
                  <NavigateNextIcon fontSize="small" />
              {
                levels?.map((level, index) => {
                  const viewRoute = `/${pathnames[0]}/view/area/${level.id}`;
                  const isLast = index === levels.length - 1;
                  return (
                    <LocationState 
                      key={index}
                      name={level.name}
                      routeTo={viewRoute}
                      history={history}
                      isLast={isLast}
                      subtitle={subTitle}
                    />

                  )
                })
              }
              </>
            :
              pathnames.map((name, index) => {
                const isLast   = index === pathnames.length - 1;
                const mainPage   = pathnames.length === 1 || listType === ALL.toLowerCase();
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
  
                return (
                  <State
                    key={index}
                    index={index}
                    mainPage={mainPage}
                    isLast={isLast}
                    name={name}
                    routeTo={routeTo}
                    subtitle={subTitle}
                    history={history}
                    pathname={pathname}
                  />
                )
              })
          }
        </Breadcrumbs>
      </Typography>
      <br/>
    </>
  )
}

export default withRouter(Title);